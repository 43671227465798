export enum ViewType {
    HOMEPAGE = "homepage",
    OFFER_LIST_FOR_SALE = "offer_list_for_sale",
    OFFER_LIST_FOR_RENT = "offer_list_for_rent",
    OFFER_LIST_ALL = "offer_list_all",
    SIMILAR_OFFER_MODAL = "similar_offer_modal",
    INVESTMENT = "investment",
    AGENCY = "agency",
    AGENT = "agent",
    DEVELOPER = "developer",
    OFFER = "offer",
    OFFER_IN_LISTING = "offer_in_listing",
    ABOUT_US = "about_us",
    BLOG = "blog",
    BLOG_ARTICLE = "blog_article",
    BLOG_AUTHORS = "blog_authors",
    BLOG_AUTHOR = "blog_author",
    FOR_AGENT = "for_agent",
    FAVOURITES = "favourites",
    PAGES_PAGE = "pages"
}

// here we keep only GTM specified values
// https://docs.google.com/spreadsheets/d/17Aqul6Wn2cGZz8OSHexsUdRral20OnuArQ-hbK0uakc/edit#gid=1610419594
export const getViewTypeName = (viewType: ViewType): string => {
    switch (viewType) {
        case ViewType.HOMEPAGE:
            return "Strona główna";
        case ViewType.OFFER_LIST_FOR_SALE:
            return "Listing - na sprzedaż";
        case ViewType.OFFER_LIST_FOR_RENT:
            return "Listing - do wynajęcia";
        case ViewType.OFFER_LIST_ALL:
            return "Listing - wszystkie";
        case ViewType.SIMILAR_OFFER_MODAL:
            return "Similar offer - modal";
        case ViewType.INVESTMENT:
            return "Widok inwestycji";
        case ViewType.AGENCY:
            return "Widok agencji";
        case ViewType.AGENT:
            return "Widok agenta";
        case ViewType.DEVELOPER:
            return "Widok dewelopera";
        case ViewType.OFFER:
            return "Widok oferty";
        case ViewType.OFFER_IN_LISTING:
            return "Widok oferty - listing";
        case ViewType.ABOUT_US:
            return "O nas";
        case ViewType.BLOG:
            return "Blog";
        case ViewType.BLOG_ARTICLE:
            return "Blog - artykuł";
        case ViewType.BLOG_AUTHORS:
            return "Widok listy autorów";
        case ViewType.BLOG_AUTHOR:
            return "Widok autora";
        case ViewType.FOR_AGENT:
            return "Dla pośrednika";
        case ViewType.FAVOURITES:
            return "Ulubione";
        case ViewType.PAGES_PAGE:
            return "Strony - strona";
        default:
            return "Inne";
    }
};
