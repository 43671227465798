import * as React from "react";
import {CSSProperties} from "react";
import {css, keyframes} from "@emotion/react";
import styled from "@emotion/styled";

interface IProps {
    size?: string;
    color?: string;
    opacity?: number;
    className?: string;
    style?: CSSProperties;
}

export const BounceLoader = (props: IProps) => {
    return (
        <LoaderHolder data-testid="loader" size={props.size} className={props.className} style={props.style}>
            <span css={bounceLoader(0, props)} />
            <span css={bounceLoader(1, props)} />
        </LoaderHolder>
    );
};

interface IBounceLoaderHolder {
    size?: string;
}

interface IBounceLoader {
    size?: string;
    color?: string;
    opacity?: number;
}

const LoaderHolder = styled.div<IBounceLoaderHolder>`
    position: relative;
    width: ${(props) => props.size || "60px"};
    height: ${(props) => props.size || "60px"};
`;

const animation = keyframes`
    0%, 100% {transform: scale(0)}
    50% {transform: scale(1.0)}
`;

const bounceLoader = (delay: number, props: IBounceLoader) => css`
    height: ${props.size || "60px"};
    width: ${props.size || "60px"};
    position: absolute;
    background-color: ${props.color || "#9069c0"};
    border-radius: 100%;
    opacity: ${props.opacity || 0.6};
    top: 0;
    left: 0;
    animation-fill-mode: both;
    animation: 2.1s ease-in-out ${delay}s infinite normal none running ${animation};
`;
