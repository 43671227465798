export interface IOfferListApiResponse<T> {
    data: T[];
    meta: IOfferListApiResponseMeta;
}

export interface IOfferListResponseSSR<T> extends IOfferListApiResponse<T> {
    collectionCount: number;
    pageCount: number;
    page: number;
}

export type IOfferListApiResponseMeta = {
    features: ISearchFeature[];
    total_investments: number;
    total_offers: number; // collapsed: offers that were grouped together by their investment
    total_offers_not_collapsed: number; // not grouped by investment, true "total"
    total_results: number;
    total_individual_offers: number;
    to_extend: boolean;
    distance: number;
};

export interface ISearchFeature {
    feature: IFeaturesTypes;
    display_name: string;
    doc_count: number;
}

export type IFeaturesTypes = SearchFeaturesApartment | SearchFeaturesHouse | SearchFeaturesLots;

export enum SearchFeaturesApartment {
    GARDEN = "garden",
    BALCONIES = "balconies",
    TERRACES = "terraces",
    LAST_FLOOR = "last_floor",
    RENOVATION = "renovation",
    INHABITANCY = "inhabitancy"
}

export enum SearchFeaturesHouse {
    DETACHED = "detached",
    TERRACED = "terraced",
    TERRACES = "terraces",
    SEMI_DETACHED = "semi_detached",
    GARAGE = "garage",
    ATTIC = "attic"
}

export enum SearchFeaturesLots {
    BUILDING = "building",
    RECREATION = "recreation",
    LAKE = "lake",
    SEA = "sea",
    MOUNTAINS = "mountains",
    UTILITIES = "utilities",
    HOUSE = "house",
    RIVER = "river"
}
