import {FormFieldType} from "@web2/form2";

export const searchFormFields = {
    search: FormFieldType.Input,
    offer_type: FormFieldType.SearchOfferType,
    deal_type: FormFieldType.Select,
    rooms: FormFieldType.Rooms,
    price: FormFieldType.InputRange,
    size: FormFieldType.InputRange,
    price_per_sqm: FormFieldType.InputRange,
    available_from: FormFieldType.InputRange,
    floor: FormFieldType.InputRange,
    construction_year: FormFieldType.InputRange,
    features: FormFieldType.FeatureList,
    is_private: FormFieldType.Checkbox
};

export type SearchFormValuesType = Record<keyof typeof searchFormFields, any>; // TODO: retype this, reuse this type in store definition (file: searchReducer.ts)
