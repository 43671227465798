/* eslint-disable @typescript-eslint/no-explicit-any */
import {includes, isArray, isEmpty, isFinite, isNaN, isNumber, isUndefined, map, reduce} from "lodash";
import {FormFieldClassMap, FormFieldType} from "@web2/form2";

import {QueryValues} from "./to_query_values";

const safeParseInt = (value: string) => {
    const parsed = parseInt(value, 10);
    return isNaN(parsed) ? value : parsed;
};
const strEmpty = (value: any, defaultValue = "") => (value == null ? defaultValue : value);

const strToInt = (input: string | number | null | undefined): number | null | undefined => (input == null || isNumber(input) ? input : parseInt(input, 10));

export const fromQueryToFormValues = <T>(types: FormFieldClassMap, queryValues: QueryValues): T => {
    return reduce(
        types,
        (acc: any, type: FormFieldType, key: string) => {
            switch (type) {
                case FormFieldType.Input:
                    return {...acc, [key]: strEmpty(queryValues[key])};
                case FormFieldType.Checkbox:
                    let value;
                    if (isUndefined(queryValues[key])) {
                        value = "";
                    } else {
                        value = queryValues[key] ? true : false;
                    }
                    return {...acc, [key]: value};
                case FormFieldType.Radio:
                case FormFieldType.Select:
                    return {...acc, [key]: safeParseInt(queryValues[key]) || ""};
                case FormFieldType.InputRange:
                case FormFieldType.SelectRange:
                    const lower = queryValues[`${key}__gte`];
                    const upper = queryValues[`${key}__lte`];
                    return {
                        ...acc,
                        [key]: {
                            lower: strEmpty(lower),
                            upper: strEmpty(upper)
                        }
                    };
                case FormFieldType.SelectRangeNumber:
                    const intLower = strToInt(queryValues[`${key}__gte`]);
                    const intUpper = strToInt(queryValues[`${key}__lte`]);
                    return {
                        ...acc,
                        [key]: {
                            lower: strEmpty(intLower),
                            upper: strEmpty(intUpper)
                        }
                    };
                case FormFieldType.Rooms:
                    return {
                        ...acc,
                        [key]: queryValues[key] ? map(queryValues[key].split(","), (i) => parseInt(i, 10)) : []
                    };
                case FormFieldType.SearchOfferType:
                    if (queryValues[key] == null) {
                        return {...acc, [key]: []};
                    }
                    return {
                        ...acc,
                        [key]: queryValues[key] ? queryValues[key].split(",") : []
                    };
                case FormFieldType.FeatureList:
                    if (queryValues[key] == null) {
                        return {...acc, [key]: []};
                    }
                    return {
                        ...acc,
                        [key]: queryValues[key] ? queryValues[key].slice(1, queryValues[key].length - 1).split(",") : []
                    };
                default:
                    throw new Error(`fromQueryToFormValues: not implemented for ${key}, formFieldType is ${type}`);
            }
        },
        {}
    );
};

/**
 * Additional helpers
 */
// skip values that are not properly parsed numbers
type ParsableQueryValue = string | number | (string | number)[] | boolean;
export const parseListedQueryValuesToNumber = (queryValues: {[key: string]: ParsableQueryValue}, keyList: string[]) => {
    return reduce(
        queryValues,
        (acc, val: ParsableQueryValue, key: string) => {
            if (!includes(keyList, key)) {
                return {...acc, [key]: val};
            }
            if (isArray(val)) {
                const numberArray = reduce(
                    val,
                    (acc2: any, v) => {
                        const vInt = isFinite(v) ? v : parseInt(v as string, 10); // parseInt handles not only strings
                        return isFinite(vInt) ? [...acc2, vInt] : acc2;
                    },
                    []
                );
                return {...acc, [key]: numberArray};
            }
            const valInt = isFinite(val) ? val : parseInt(val as string, 10); // parseInt handles not only strings
            return isFinite(valInt) ? {...acc, [key]: valInt} : acc;
        },
        {}
    );
};

export const parseListedQueryValuesToString = (queryValues: {[key: string]: ParsableQueryValue}) => {
    return reduce(
        queryValues,
        (acc, val: ParsableQueryValue, key: string) => {
            if (isArray(val)) {
                if (isEmpty(val)) {
                    return acc;
                }
                const stringArray = reduce(
                    val,
                    (acc2: any, v) => {
                        return v != null ? [...acc2, v.toString()] : acc2;
                    },
                    []
                );
                return {...acc, [key]: stringArray};
            }
            if (typeof val === "boolean") {
                return {...acc, [key]: val ? "true" : "false"};
            }

            return val != null ? {...acc, [key]: val.toString()} : acc;
        },
        {}
    );
};
