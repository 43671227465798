import {ViewType} from "../../view_type/view_type";
import {IGtmProductData} from "../ecommerce_events/gtm_event_typings";

export const getGtmNameAndId = (props: IGtmProductData) => {
    switch (props.viewType) {
        case ViewType.AGENCY:
        case ViewType.DEVELOPER:
            return {
                name: props.agency?.name ?? "na",
                id: props.agency?.id ?? "na"
            };
        case ViewType.AGENT:
            return {
                name: props.agent ? `${props.agent.name} ${props.agent.last_name}` : "na",
                id: props.agent?.id ?? "na"
            };
        case ViewType.INVESTMENT:
            return {
                name: props.investment?.name ?? "na",
                id: props.investment?.id ?? "na"
            };
        default:
            return {
                name: "na",
                id: "na"
            };
    }
};
