import * as React from "react";
import styled from "@emotion/styled";

export interface IProps {
    children: React.ReactNode;
    className?: string;
}

export const CenterBox = (props: IProps) => {
    return (
        <CenterBoxHolder className={props.className}>
            <CenteredBox>{props.children}</CenteredBox>
        </CenterBoxHolder>
    );
};

const CenterBoxHolder = styled.div`
    display: flex;
    flex-grow: 1;
    height: 100%;
`;

const CenteredBox = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0;
    align-items: center;
    justify-content: center;
`;
